/**
 * @license
 *
 * Copyright IBM Corp. 2019, 2021
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */

import settings from 'carbon-components/es/globals/js/settings';
import { classMap } from 'lit-html/directives/class-map';
import { html, property, customElement, LitElement } from 'lit-element';
import FocusMixin from '../../globals/mixins/focus';
import BXSideNavMenu from './side-nav-menu';
import styles from './side-nav.scss';

const { prefix } = settings;

/**
 * Side nav menu item.
 * @element bx-side-nav-menu-item
 * @csspart link The link.
 * @csspart title The title.
 */
@customElement(`${prefix}-side-nav-menu-item`)
class BXSideNavMenuItem extends FocusMixin(LitElement) {
  /**
   * `true` if the menu item should be active.
   */
  @property({ type: Boolean, reflect: true })
  active = false;

  /**
   * Link `href`.
   */
  @property()
  href = '';

  /**
   * The title.
   */
  @property()
  title!: string;

  createRenderRoot() {
    return this.attachShadow({
      mode: 'open',
      delegatesFocus: Number((/Safari\/(\d+)/.exec(navigator.userAgent) ?? ['', 0])[1]) <= 537,
    });
  }

  shouldUpdate(changedProperties) {
    if (changedProperties.has('active') && this.active) {
      const { selectorMenu } = this.constructor as typeof BXSideNavMenuItem;
      const parent = this.closest(selectorMenu);
      if (parent) {
        (parent as BXSideNavMenu).active = true;
      }
    }
    return true;
  }

  render() {
    const { active, href, title } = this;
    const classes = classMap({
      [`${prefix}--side-nav__link`]: true,
      [`${prefix}--side-nav__link--current`]: active,
    });
    return html`
      <a part="link" class="${classes}" href="${href}">
        <span part="title" class="${prefix}--side-nav__link-text">
          <slot>${title}</slot>
        </span>
      </a>
    `;
  }

  /**
   * A selector that will return the parent menu.
   */
  static get selectorMenu() {
    return `${prefix}-side-nav-menu`;
  }

  static styles = styles; // `styles` here is a `CSSResult` generated by custom WebPack loader
}

export default BXSideNavMenuItem;
