/**
 * @license
 *
 * Copyright IBM Corp. 2020
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */

import { html, property, customElement, LitElement } from 'lit-element';
import settings from 'carbon-components/es/globals/js/settings';
import styles from './data-table.scss';

const { prefix } = settings;

/**
 * Table row of collapsible details.
 * @element bx-table-expanded-row
 */
@customElement(`${prefix}-table-expanded-row`)
class BXTableExpandedRow extends LitElement {
  /**
   * The colspan.
   */
  @property({ type: Number, attribute: 'colspan' })
  colSpan = 1;

  /**
   * `true` if the table row should be expanded.
   */
  @property({ type: Boolean, reflect: true })
  expanded = false;

  /**
   * `true` if the table row should be highlighted.
   */
  @property({ type: Boolean, reflect: true })
  highlighted = false;

  render() {
    const { colSpan } = this;
    return html`
      <td colspan="${colSpan}">
        <div class="${prefix}--child-row-inner-container">
          <slot></slot>
        </div>
      </td>
    `;
  }

  static styles = styles; // `styles` here is a `CSSResult` generated by custom WebPack loader
}

export default BXTableExpandedRow;
