/**
 * @license
 *
 * Copyright IBM Corp. 2020, 2021
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */

import { html, property, customElement, LitElement } from 'lit-element';
import settings from 'carbon-components/es/globals/js/settings.js';
import ifNonNull from '../../globals/directives/if-non-null';
import FocusMixin from '../../globals/mixins/focus';
import styles from './skip-to-content.scss';

const { prefix } = settings;

/**
 * Skip-to-content link.
 * @element bx-skip-to-content
 */
@customElement(`${prefix}-skip-to-content`)
class BXSkipToContent extends FocusMixin(LitElement) {
  /**
   * The assistive text for the link,
   */
  @property({ attribute: 'link-assistive-text' })
  linkAssisstiveText = 'Skip to main content';

  /**
   * The skip link href.
   */
  @property()
  href?: string;

  createRenderRoot() {
    return this.attachShadow({
      mode: 'open',
      delegatesFocus: Number((/Safari\/(\d+)/.exec(navigator.userAgent) ?? ['', 0])[1]) <= 537,
    });
  }

  connectedCallback() {
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'navigation');
    }
    super.connectedCallback();
  }

  render() {
    const { linkAssisstiveText } = this;
    return html` <a class="${prefix}--skip-to-content" href="${ifNonNull(this.href)}"><slot>${linkAssisstiveText}</slot></a> `;
  }

  updated(changedProperties) {
    if (changedProperties.has('linkAssisstiveText')) {
      const { linkAssisstiveText } = this;
      this.setAttribute('aria-label', linkAssisstiveText);
    }
  }

  static styles = styles; // `styles` here is a `CSSResult` generated by custom WebPack loader
}

export default BXSkipToContent;
