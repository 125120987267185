/**
 * @license
 *
 * Copyright IBM Corp. 2019, 2021
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */

import { html, property, customElement, LitElement } from 'lit-element';
import ChevronDown16 from '@carbon/icons/lib/chevron--down/16';
import settings from 'carbon-components/es/globals/js/settings';
import FocusMixin from '../../globals/mixins/focus';
import styles from './pagination.scss';

const { prefix } = settings;

/**
 * The select box for the current page.
 * @element bx-pages-select
 * @fires bx-pages-select-changed - The custom event fired after the page is changed.
 */
@customElement(`${prefix}-pages-select`)
class BXPagesSelect extends FocusMixin(LitElement) {
  /**
   * Handles `change` event on the `<select>` to select page size.
   */
  private _handleChange({ target }: Event) {
    const value = Number((target as HTMLSelectElement).value);
    this.dispatchEvent(
      new CustomEvent((this.constructor as typeof BXPagesSelect).eventChange, {
        bubbles: true,
        composed: true,
        detail: {
          value,
        },
      })
    );
    this.value = value;
  }

  /**
   * The formatter for the assistive text for screen readers to announce.
   * Should be changed upon the locale the UI is rendered with.
   */
  @property({ attribute: false })
  formatLabelText = ({ count }) => `Page number, of ${count} page${count <= 1 ? '' : 's'}`;

  /**
   * The formatter for the text next to the select box. Should be changed upon the locale the UI is rendered with.
   */
  @property({ attribute: false })
  formatSupplementalText = ({ count }) => `of ${count} page${count <= 1 ? '' : 's'}`;

  /**
   * The number of total pages.
   */
  @property({ type: Number })
  total!: number;

  /**
   * The value, working as the current page, index that starts with zero.
   */
  @property({ type: Number })
  value!: number;

  createRenderRoot() {
    return this.attachShadow({
      mode: 'open',
      delegatesFocus: Number((/Safari\/(\d+)/.exec(navigator.userAgent) ?? ['', 0])[1]) <= 537,
    });
  }

  render() {
    const { formatLabelText, formatSupplementalText, total, value, _handleChange: handleChange } = this;
    // `<option ?selected="${index === value}">` is a workaround for:
    // https://github.com/Polymer/lit-html/issues/1052
    return html`
      <div class="${prefix}--select__page-number">
        <label for="select" class="${prefix}--label ${prefix}--visually-hidden"> ${formatLabelText({ count: total })} </label>
        <select class="${prefix}--select-input" .value="${value}" @change="${handleChange}">
          ${Array.from(new Array(total)).map(
            (_item, index) => html` <option value=${index} ?selected="${index === value}">${index + 1}</option> `
          )}
        </select>
        ${ChevronDown16({ class: `${prefix}--select__arrow` })}
      </div>
      <span class="${prefix}--pagination__text"> ${formatSupplementalText({ count: total })} </span>
    `;
  }

  /**
   * The name of the custom event fired after the page is changed.
   */
  static get eventChange() {
    return `${prefix}-pages-select-changed`;
  }

  static styles = styles; // `styles` here is a `CSSResult` generated by custom WebPack loader
}

export default BXPagesSelect;
