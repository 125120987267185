/**
 * @license
 *
 * Copyright IBM Corp. 2019, 2021
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */

import settings from 'carbon-components/es/globals/js/settings';
import { ifDefined } from 'lit-html/directives/if-defined';
import { html, property, customElement, LitElement } from 'lit-element';
import FocusMixin from '../../globals/mixins/focus';
import styles from './header.scss';

const { prefix } = settings;

/**
 * The product name UI in header nav.
 * @element bx-header-name
 * @csspart link The link.
 * @csspart prefix The prefix content.
 */
@customElement(`${prefix}-header-name`)
class BXHeaderName extends FocusMixin(LitElement) {
  /**
   * Link `href`.
   */
  @property()
  href!: string;

  /**
   * The product name prefix.
   */
  @property()
  prefix!: string;

  createRenderRoot() {
    return this.attachShadow({
      mode: 'open',
      delegatesFocus: Number((/Safari\/(\d+)/.exec(navigator.userAgent) ?? ['', 0])[1]) <= 537,
    });
  }

  render() {
    const { href, prefix: namePrefix } = this;
    const namePrefixPart = !namePrefix
      ? undefined
      : html` <span part="prefix" class="${prefix}--header__name--prefix">${namePrefix}</span> `;
    return html`
      <a part="link" class="${prefix}--header__name" href="${ifDefined(href)}">${namePrefixPart}&nbsp;<slot></slot></a>
    `;
  }

  static styles = styles; // `styles` here is a `CSSResult` generated by custom WebPack loader
}

export default BXHeaderName;
